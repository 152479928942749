<template>
  <div class="doublecheck-card">
    <!-- intestazione (contestuale al fatto se la prenotazione è stata confermata o meno) -->
    <div class="doublecheck--section">
      <div v-if="info.confirmed">
        <h2 class="vb-heading" v-html="strings.confirm_title[lang]" />
        <p class="my-4" v-html="strings.confirm_subtitle[lang]" />
      </div>
      <div v-else>
        <h2 class="vb-heading" v-html="strings.simple_checkin_title[lang]" />
      </div>
    </div>

    <!-- fascia recap prenotazione sempre visibile -->
    <div class="doublecheck--section">
      <h5 class="vb-heading" v-html="strings.summary_title[lang]" />
      <table class="info">
        <tr>
          <td class="name" v-html="strings.reservation[lang]" />
          <td class="value" v-html="info.summary.reservation_id" />
        </tr>
        <!--<tr>
          <td class="name" v-html="strings.confirmation_number[lang]" />
          <td class="value" v-html="info.summary.confirmationNumber" />
        </tr>-->
        <tr>
          <td class="name" v-html="strings.guest[lang]" />
          <td class="value" v-html="info.summary.bookingName" />
        </tr>
        <tr>
          <td class="name" v-html="strings.guestPhone[lang]" />
          <td class="value" v-html="info.summary.guestPhone" />
        </tr>
        <tr>
          <td class="name" v-html="strings.property[lang]" />
          <td class="value" v-html="info.summary.property" />
        </tr>
        <tr>
          <td class="name">check-in</td>
          <td class="value" v-html="info.summary.checkin" />
        </tr>
        <tr>
          <td class="name">check-out</td>
          <td class="value" v-html="info.summary.checkout" />
        </tr>
        <tr>
          <td class="name" v-html="strings.room[lang]" />
          <td class="value" v-html="info.summary.rooms" />
        </tr>
        <tr>
          <td class="name" v-html="strings.adults[lang]" />
          <td class="value" v-html="info.summary.adults" />
        </tr>
        <tr>
          <td class="name" v-html="strings.children[lang]" />
          <td class="value" v-html="info.summary.minors" />
        </tr>
      </table>
    </div>

    <!-- fascia conferma prenotazione -->
    <div v-if="!info.confirmed" class="doublecheck--section pt-4">
      <p class="vb-heading">
        <strong v-html="strings.check_subtitle[lang]" />
      </p>
      <vb-button
        :vb_options="{
          text: strings.confirm_btn[lang]
        }"
        @click="confirm"
      />      
    </div>

    <!-- fascia info e contatti sempre visibile -->
    <div class="doublecheck--section">
      <p v-html="strings.text_always_visible[lang]"> </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'doublecheck-card',
    components: {},
    props: {
      lang: String,
      info: Object,
      strings: Object,
    },
    data() {
      return {
        loading: true,
      }
    },
    methods: {
      confirm() {
        this.$emit('confirm')
      },
    },
    mounted() {}
  }
</script>

<style lang="scss">
  .doublecheck {
    &--section {
      display:block;
      text-align: center;
      max-width: 600px;
      margin:0 auto;
      margin-bottom:1rem;
      margin-top: 2rem;
      table {
        margin: 1rem auto;
        padding: 0;
        width: 100%;
        max-width: 320px;
        td {
          padding:0.25rem 0;
          text-align: left;
          &:first-child {
          }
          &:last-child {
            text-align: center;
          }
        }
        .name {
          font-weight:600;
        }
        &.info {
          td {
            width:50%;
          }
        }
      }
    }
  }
</style>